import gql from "graphql-tag";

export const requestsGql = gql`
  query Requests($input: JobFilterInput!) {
    requests(input: $input) {
      ... on RequestsResults {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        results {
          id
          performer {
            id
            nickname
          }
          outputVideos {
            id
            embedUrl
            status
            hasBackgroundSound
          }
          isDownloadAvailable
          notes
          script
          order {
            id
            status {
              id
              key
            }
            notes
            videoOutputLink
            targetDate
          }
          template {
            id
            title
            thumbnail
            script
            videoUrls
          }
          status {
            id
            key
            name
          }
          conversation {
            id
            messages {
              id
              created
              author {
                id
                username
                nickname
              }
              content
            }
          }
        }
      }
      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
