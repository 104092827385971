
import { Requests_requests_RequestsResults_results } from "@/api/order/__generated__/Requests";
import { useDownloadRequestVideos } from "@/shared/composables/useDownloadRequestVideos";
import { getJobStatusName } from "@/shared/utils/jobStatus";
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";
import { DownloadOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  props: {
    request: {
      type: Object as PropType<Requests_requests_RequestsResults_results>,
      required: true,
    },
    hideStatusButtons: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const { isMobile } = useWindowSizeChecker();

    const { downloadVideos, downloadLoading } = useDownloadRequestVideos(
      computed(() => props.request.id)
    );

    return {
      t,
      isMobile,
      getJobStatusName,
      downloadVideos,
      downloadLoading,
    };
  },
  components: { DownloadOutlined },
});
