import gql from "graphql-tag";

export const subscribeToPlanGql = gql`
  mutation SubscribeToPlan($input: SubscribeToPlanInput) {
    subscribeToPlan(input: $input) {
      ... on User {
        subscriptionPlan {
          id
          name
          price
          maxVideoCount
          description
        }
      }

      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
