import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { currentSubscriptionPlanGql } from "@/api/plans/currentSubscriptionPlan";
import { subscribeToPlanGql } from "@/api/plans/subscribeToPlan";
import { subscriptionPlansGql } from "@/api/plans/subscriptionPlans";
import {
  CurrentSubscriptionPlan,
  CurrentSubscriptionPlan_profile_User,
} from "@/api/plans/__generated__/CurrentSubscriptionPlan";
import {
  SubscribeToPlan,
  SubscribeToPlanVariables,
  SubscribeToPlan_subscribeToPlan_User,
} from "@/api/plans/__generated__/SubscribeToPlan";
import {
  SubscriptionPlans,
  SubscriptionPlans_subscriptionPlans_SubscriptionPlanResults,
  SubscriptionPlans_subscriptionPlans_SubscriptionPlanResults_results,
} from "@/api/plans/__generated__/SubscriptionPlans";
import { useQuery } from "@vue/apollo-composable";
import { orderBy } from "lodash";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { HideIfErrorCode } from "@/shared/utils/graphql/errorHandler";

export const useSubscriptionPlans = (hideErrors = false) => {
  const store = useStore();

  // === ALL SUBSCRIPTION PLANS ===
  const {
    result: subscriptionPlansResult,
    loading: subscriptionPlansLoading,
    error: subscriptionPlansError,
    refetch: refetchSubscriptionPlans,
  } = useQuery<SubscriptionPlans>(subscriptionPlansGql);

  const subscriptionPlans = computed<
    SubscriptionPlans_subscriptionPlans_SubscriptionPlanResults_results[]
  >(() => {
    const response =
      parseGqlResponse<SubscriptionPlans_subscriptionPlans_SubscriptionPlanResults>(
        "SubscriptionPlanResults",
        subscriptionPlansResult.value,
        hideErrors ? HideIfErrorCode.ALL_ERRORS : null
      );

    return orderBy(response.data?.results, ["sortWeight"], ["desc"]);
  });

  // === CURRENT SUBSCRIPTION PLAN ===
  const {
    result: currentSubscriptionPlanResult,
    loading: currentSubscriptionPlanLoading,
    refetch: currentSubscriptionPlanRefetch,
  } = useQuery<CurrentSubscriptionPlan>(currentSubscriptionPlanGql);

  const currentSubscriptionPlan = computed(() => {
    return parseGqlResponse<CurrentSubscriptionPlan_profile_User>(
      "User",
      currentSubscriptionPlanResult.value,
      hideErrors ? HideIfErrorCode.ALL_ERRORS : null
    ).data?.subscriptionPlan;
  });

  const hasCurrentPlan = computed(() => !!currentSubscriptionPlan.value);

  const maxPerformerCount = computed(
    () => currentSubscriptionPlan.value?.maxPerformerCount ?? 0
  );

  const maxVideoCount = computed(
    () => currentSubscriptionPlan.value?.maxVideoCount ?? 0
  );

  const fetchCurrentSubscriptionPlan = async () => {
    await currentSubscriptionPlanRefetch();
    store.dispatch("authSubscriptionPlanUpdate", currentSubscriptionPlan.value);
  };

  // Subscribe to Plan
  const { mutate: subscribeToPlanMutate, loading: subscribeToPlanLoading } =
    useCustomMutation<SubscribeToPlan, SubscribeToPlanVariables>(
      subscribeToPlanGql
    );

  const subscribeToPlan = async (planId: string) => {
    const response = await subscribeToPlanMutate({
      input: { subscriptionPlanId: planId },
    });

    const parsedResponse =
      parseGqlResponse<SubscribeToPlan_subscribeToPlan_User>(
        "User",
        response?.data
      );

    if (parsedResponse.data) {
      const plan = parsedResponse.data?.subscriptionPlan;

      store.dispatch("authSubscriptionPlanUpdate", plan);
    }
  };

  // Fetch Current Subscription Plan onMount to update vuex store
  onMounted(() => {
    fetchCurrentSubscriptionPlan();
  });

  // === OTHER Helper Functions ===
  const getSubscriptionPlanLimitsById = (id: string) => {
    const plan = subscriptionPlans.value.find((plan) => plan.id === id);

    if (plan) {
      return {
        numberOfVideos: plan.maxVideoCount,
        numberOfPerformers: plan.maxPerformerCount,
        testimonialLockInMonths: plan.testimonialLockInMonths,
      };
    }

    return {
      numberOfVideos: 0,
      numberOfPerformers: 0,
      testimonialLockInMonths: 0,
    };
  };

  // === OTHER Helper Functions ===
  const getSubscriptionPlanByLimits = (
    numOfVideos?: number,
    numOfPerformers?: number
  ) => {
    return subscriptionPlans.value.find(
      (plan) =>
        plan.maxVideoCount == numOfVideos &&
        plan.maxPerformerCount == numOfPerformers
    );
  };

  return {
    subscriptionPlans,
    subscriptionPlansLoading,
    subscriptionPlansError,
    refetchSubscriptionPlans,

    currentSubscriptionPlan,
    currentSubscriptionPlanLoading,
    hasCurrentPlan,
    fetchCurrentSubscriptionPlan,
    maxPerformerCount,
    maxVideoCount,

    subscribeToPlan,
    subscribeToPlanLoading,

    getSubscriptionPlanLimitsById,
    getSubscriptionPlanByLimits,
  };
};
