export const JobStatus = {
  OPEN: "OPEN",
  SUBMITTED_BY_PERFORMER: "SUBMITTED_BY_PERFORMER",
  CREATOR_VIDEO_CHECKED: "CREATOR_VIDEO_CHECKED",
  CLIENT_APPROVED: "CLIENT_APPROVED",
  CLIENT_DENIED: "CLIENT_DENIED",
  CLIENT_COMMENTED: "CLIENT_COMMENTED",
  CLIENT_COMMENT_ACCEPTED: "CLIENT_COMMENT_ACCEPTED",
  CLIENT_COMMENT_REJECTED: "CLIENT_COMMENT_REJECTED",
};
