import gql from "graphql-tag";

export const subscriptionPlansGql = gql`
  query SubscriptionPlans {
    subscriptionPlans {
      ... on SubscriptionPlanResults {
        results {
          id
          name
          price
          maxVideoCount
          maxPerformerCount
          description
          isActive
          sortWeight
          testimonialLockInMonths
        }
      }

      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
