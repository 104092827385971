
import { defineComponent, PropType, computed, ref } from "vue";
import { Requests_requests_RequestsResults_results } from "@/api/order/__generated__/Requests";
import InputTextArea from "@/shared/components/Forms/InputTextArea.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { usePerformerDetails } from "@/shared/composables/usePerformerDetails";
import { isOutputVideoViewable } from "@/shared/composables/useRequests";
import Avatar from "@/shared/components/Avatar.vue";
import VideoEmbedTab from "@/shared/components/VideoEmbed/VideoEmbedTab.vue";
import RequestCardStatus from "@/shared/components/Requests/RequestCardStatus.vue";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import isSameDay from "date-fns/isSameDay";
import { formatDateTime } from "@/shared/utils/date";
import { OrderStatus } from "@/shared/types/OrderStatus";
import { getJobVideosEmbeds } from "@/shared/utils/jobVideoUtils";
import { getCustomPerformerTexts } from "@/shared/utils/jobStatus";

export default defineComponent({
  props: {
    request: Object as PropType<Requests_requests_RequestsResults_results>,

    showComments: Boolean,
    comment: String,
    commentFormName: Array,
    commentRules: Array,

    useSmallTitles: Boolean,
    isReviewing: Boolean,
    hideStatusButtons: Boolean,
  },
  emits: ["on-video-refresh"],
  setup(props) {
    const { t } = useI18n();
    const store = useStore();

    const scriptVisible = ref(false);

    const username = computed(() => store.state.auth.username);

    const { performerDetails, performerDetailsLoading } = usePerformerDetails(
      {
        input: { id: props.request?.performer?.id ?? "" },
      },
      { hideError: true }
    );

    const performerNickname = computed(
      () =>
        performerDetails.value?.performerProfile?.nickname ?? t("Model Creator")
    );

    const videoDeadlineText = computed<string>(() => {
      const today = Date.now();
      const deadline = props.request?.order?.targetDate;

      // If has deadline
      if (deadline) {
        const deadlineDate = new Date(deadline);

        // If today
        if (isSameDay(deadlineDate, today)) {
          return t("today");
        }
        // If other date
        else {
          return formatDistanceToNow(deadlineDate, {
            includeSeconds: false,
            addSuffix: true,
          });
        }
      }

      // If deadline is not present
      return t("10 days after you ship the product");
    });

    const dynamicPerformerTexts = computed(() => {
      return getCustomPerformerTexts(props.request?.status);
    });

    return {
      t,
      scriptVisible,
      username,
      performerDetails,
      performerDetailsLoading,
      videoDeadlineText,
      formatDistanceToNow,
      formatDateTime,
      OrderStatus,
      isOutputVideoViewable,
      getJobVideosEmbeds,
      dynamicPerformerTexts,
      performerNickname,
    };
  },
  components: {
    Avatar,
    InputTextArea,
    VideoEmbedTab,
    RequestCardStatus,
  },
});
