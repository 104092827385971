import { requestsGql } from "@/api/order/requests";
import {
  Requests,
  RequestsVariables,
  Requests_requests,
  Requests_requests_RequestsResults,
  Requests_requests_RequestsResults_results,
} from "@/api/order/__generated__/Requests";
import { JobFilterInput } from "@/../__generated__/globalTypes";
import { useQuery } from "@vue/apollo-composable";
import { computed, reactive, ref, watch, watchEffect } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import get from "lodash/get";
import { OrderStatus } from "@/shared/types/OrderStatus";
import { JobStatus } from "@/shared/types/JobStatus";
import { useRouter } from "vue-router";
import { apiErrorCodes } from "../utils/constants";
import routeNames from "@/web/router/routeNames";

/** FIXME: Add more items once BE optimization for Vimeo links are implemented. See: #865cpw2mj */
export const DEFAULT_PAGE_SIZE = 4;

const defaultFilter: JobFilterInput = {
  pagination: {
    pageSize: DEFAULT_PAGE_SIZE,
    after: null,
  },
};

export type UseRequestsOptions = {
  /** Initial values for {@link JobFilterInput} without pagination. */
  initialFilter?: Partial<Omit<JobFilterInput, "pagination">>;
  /** Page size to use in number. Default: {@link DEFAULT_PAGE_SIZE} */
  pageSize: number;
  /** Set to false to disable the query on use of composable. Useful if waiting for filter values. */
  queryEnabled: boolean;
};

export const defaultUseRequestsOptions: UseRequestsOptions = {
  pageSize: DEFAULT_PAGE_SIZE,
  queryEnabled: true,
};

/**
 * Composable to use `Query.requests` endpoint
 * @param options Extra options
 * @returns A reusable composable function.
 */
export const useRequests = (
  _options: Partial<UseRequestsOptions> = defaultUseRequestsOptions
) => {
  const router = useRouter();

  const options: UseRequestsOptions = {
    ...defaultUseRequestsOptions,
    ..._options,
  };

  const requestsFilter = reactive<JobFilterInput>({
    ...defaultFilter,
    ...options.initialFilter,
    pagination: {
      after: null,
      pageSize: options.pageSize,
    },
  });

  const requestsQueryEnabled = computed(() => !!requestsFilter.orderId);

  /**
   * Used to track whether a job as a video in transcoding status.
   * To determine whether to use `pollInterval`
   */
  const hasTranscodingVideo = ref(false);

  const {
    result: requestsResult,
    loading: requestsLoading,
    refetch: refetchRequests,
    fetchMore,
  } = useQuery<Requests, RequestsVariables>(
    requestsGql,
    () => ({
      input: requestsFilter,
    }),
    () => ({
      enabled: requestsQueryEnabled.value,
      // FIXME: Re-enable Poll Interval once BE optimization on Vimeo is done. See: #865cpw2mj
      // pollInterval: hasTranscodingVideo.value ? 20000 : 0,
      // fetchPolicy: "network-only",
      fetchPolicy: "cache-first",
    })
  );

  const requestsParsedResponse = computed(() =>
    parseGqlResponse<Requests_requests_RequestsResults>(
      "RequestsResults",
      requestsResult.value
    )
  );

  const requestsResponse = computed(() => requestsParsedResponse.value.data);

  /**
   * Consider Vimeo transcoding status when
   * - `outputVideo.status` is not null
   * - `outputVideo.status` is not equal to "available"
   */
  watch([requestsResult], () => {
    hasTranscodingVideo.value = !!requestsResponse.value?.results?.find((job) =>
      job?.outputVideos?.some((video) => video?.status !== "available")
    );
  });

  const requestsPageInfo = computed(() => {
    return requestsResponse.value?.pageInfo;
  });

  const requests = computed(() => {
    return requestsResponse.value?.results;
  });

  //   ===== Fetch More =====
  const requestsFetchMoreLoading = ref(false);

  const requestsFetchMore = async () => {
    if (
      requestsPageInfo.value?.hasNextPage &&
      requestsPageInfo.value.endCursor
    ) {
      requestsFetchMoreLoading.value = true;

      await fetchMore({
        variables: {
          input: {
            ...requestsFilter,
            pagination: {
              pageSize: options.pageSize,
              after: requestsPageInfo.value.endCursor,
            },
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const updatedrequestsResults = {
            ...previousResult.requests,
            ...fetchMoreResult?.requests,

            // Concatenate results array
            results: [
              ...get(previousResult.requests, "results", []),
              ...get(fetchMoreResult?.requests, "results", []),
            ],
          };

          return {
            requests: updatedrequestsResults as Requests_requests,
          };
        },
      });
    }

    requestsFetchMoreLoading.value = false;
  };

  // Automatically redirect to profile when Order ID is incorrect
  watchEffect(() => {
    if (
      options.initialFilter?.orderId &&
      requestsParsedResponse.value.error?.errors.some(
        (error) => error.code == apiErrorCodes.INVALID_INPUT.toString()
      ) &&
      [
        routeNames.orderDetail,
        routeNames.orderShippingDetails,
        routeNames.performerSelect,
        routeNames.requests,
        routeNames.reviewRequests,
        routeNames.downloadRequests,
      ].includes(router.currentRoute.value.name?.toString() ?? "")
    ) {
      router.push({ name: routeNames.profile });
    }
  });

  return {
    requests,
    requestsFilter,
    requestsLoading,
    requestsFetchMore,
    requestsFetchMoreLoading,
    requestsHasNextPage: computed(() => requestsPageInfo.value?.hasNextPage),
    requestsQueryEnabled,
    hasTranscodingVideo,
    refetchRequests,
  };
};

export const OUTPUT_VIDEO_VIEWABLE_ORDER_STATUSES = [
  OrderStatus.CLIENT_REVIEW,
  OrderStatus.CLIENT_FEEDBACK,
  OrderStatus.DONE,
];

export const OUTPUT_VIDEO_VIEWABLE_JOB_STATUSES = [
  JobStatus.CREATOR_VIDEO_CHECKED,
  JobStatus.CLIENT_APPROVED,
  JobStatus.CLIENT_DENIED,
  JobStatus.CLIENT_COMMENTED,
  JobStatus.CLIENT_COMMENT_ACCEPTED,
  JobStatus.CLIENT_COMMENT_REJECTED,
];

export const OUTPUT_VIDEO_DOWNLOADABLE_JOB_STATUSES = [
  JobStatus.CLIENT_APPROVED,
  JobStatus.CLIENT_COMMENT_REJECTED,
];

/** Check if a Request's (Job) Video Output is viewable by client. */
export const isOutputVideoViewable = (
  request: Requests_requests_RequestsResults_results
): boolean => {
  const orderStatus =
    (request.order?.status?.key as OrderStatus) ?? OrderStatus._NULL;
  const jobStatus = request.status?.key ?? "";

  return (
    OUTPUT_VIDEO_VIEWABLE_ORDER_STATUSES.includes(orderStatus) ||
    OUTPUT_VIDEO_VIEWABLE_JOB_STATUSES.includes(jobStatus)
  );
};
