
import { defineComponent, ref, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { createVimeoEmbeddedScript } from "@/shared/utils/vimeo";
import VimeoEmbedded from "@/shared/components/VimeoEmbedded.vue";

/**
 * This component handles carousel of different embed types
 */
export default defineComponent({
  props: {
    label: String,
    videoEmbeds: [],
  },
  setup() {
    const { t } = useI18n();
    const embedCarouselRef = ref();
    const models = reactive({
      activeKey: 0,
    });

    /**
     * Programmatically inject TikTok and Vimeo Embedded code in to the component
     * after all embedded html codes are ready
     */
    const handleInjectEmbedScripts = () => {
      try {
        embedCarouselRef.value.prepend(createVimeoEmbeddedScript());
      } catch (error) {
        console.log("Something went wrong when trying to inject embed scripts");
      }
    };

    /** Determines tab title based on hasBackgroundSound */
    const tabLabel = (hasBackgroundSound) =>
      hasBackgroundSound ? t("With music") : t("No music");

    /** Handler when tab changes */
    const handleAfterChange = async (current: number) => {
      pauseVimeoEmbed(current);
    };

    /** See: https://developer.vimeo.com/player/sdk/basics */
    const pauseVimeoEmbed = (index: number) => {
      try {
        const vimeoEmbed = document.querySelector(
          "#rc-tabs-1-panel-" + index + " iframe.vimeo-embed"
        );
        const _window = window as any;
        const player = new _window.Vimeo.Player(vimeoEmbed);
        player.pause();
      } catch (e) {
        console.error(e);
      }
    };

    return {
      t,
      handleInjectEmbedScripts,
      embedCarouselRef,
      models,
      tabLabel,
      handleAfterChange,
    };
  },
  components: {
    VimeoEmbedded,
  },
});
