import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadOutlined = _resolveComponent("DownloadOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_ctx.request.isDownloadAvailable && !_ctx.hideStatusButtons)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.request.isDownloadAvailable)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              size: "small",
              type: "primary",
              loading: _ctx.downloadLoading,
              onClick: _withModifiers(_ctx.downloadVideos, ["stop"])
            }, {
              default: _withCtx(() => [
                (_ctx.isMobile)
                  ? (_openBlock(), _createBlock(_component_DownloadOutlined, { key: 0 }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.t("Download")), 1)
                    ], 64))
              ]),
              _: 1
            }, 8, ["loading", "onClick"]))
          : _createCommentVNode("", true)
      ], 64))
    : (_ctx.request.status)
      ? (_openBlock(), _createBlock(_component_a_typography_text, {
          key: 1,
          class: "bordered"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getJobStatusName(_ctx.request.status)), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}