import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, renderSlot as _renderSlot, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "request-card__container flex" }
const _hoisted_2 = { class: "request-card__tiktok-col" }
const _hoisted_3 = { class: "request-card__content-col flex-1" }
const _hoisted_4 = { class: "text-right" }
const _hoisted_5 = { class: "flex items-center gap-4" }
const _hoisted_6 = { class: "request-card__performer-name" }
const _hoisted_7 = {
  key: 1,
  class: "request-card__performer-name"
}
const _hoisted_8 = { class: "flex mt-6 mb-4 gap-4" }
const _hoisted_9 = {
  key: 1,
  class: "pl-8"
}
const _hoisted_10 = { class: "mt-4 whitespace-pre-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoEmbedTab = _resolveComponent("VideoEmbedTab")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_RequestCardStatus = _resolveComponent("RequestCardStatus")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_InputTextArea = _resolveComponent("InputTextArea")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_comment = _resolveComponent("a-comment")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_ctx.request)
    ? (_openBlock(), _createBlock(_component_a_card, {
        key: 0,
        class: "request-card mb-6",
        bordered: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.isOutputVideoViewable(_ctx.request) && _ctx.request.outputVideos?.length)
                ? (_openBlock(), _createBlock(_component_VideoEmbedTab, {
                    key: 0,
                    "video-embeds": _ctx.getJobVideosEmbeds(_ctx.request),
                    class: "request-card__vimeo-embed"
                  }, null, 8, ["video-embeds"]))
                : (_openBlock(), _createBlock(_component_a_typography_text, {
                    key: 1,
                    class: "block text-center xl:mt-24",
                    strong: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Video will be shown here.")), 1)
                    ]),
                    _: 1
                  }))
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_RequestCardStatus, {
                  request: _ctx.request,
                  "hide-status-buttons": _ctx.isReviewing || _ctx.hideStatusButtons
                }, null, 8, ["request", "hide-status-buttons"])
              ]),
              (_ctx.request.id)
                ? (_openBlock(), _createBlock(_component_a_typography_text, {
                    key: 0,
                    class: "text-tag"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Video #{number}", {
              number: _ctx.request.id,
            })), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_typography_title, {
                level: _ctx.useSmallTitles ? 5 : 2,
                class: "mt-2 mb-4"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.request.template?.title), 1)
                ]),
                _: 1
              }, 8, ["level"]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Avatar, {
                  "avatar-src": _ctx.performerDetails?.performerProfile?.avatar
                }, null, 8, ["avatar-src"]),
                _createElementVNode("div", null, [
                  _createVNode(_component_a_typography_title, { level: 5 }, {
                    default: _withCtx(() => [
                      (_ctx.dynamicPerformerTexts.headingTranslationString)
                        ? (_openBlock(), _createBlock(_component_i18n_t, {
                            key: 0,
                            keypath: _ctx.dynamicPerformerTexts.headingTranslationString,
                            tag: "span"
                          }, {
                            name: _withCtx(() => [
                              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.performerNickname), 1)
                            ]),
                            _: 1
                          }, 8, ["keypath"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.performerNickname), 1))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_typography_text, null, {
                    default: _withCtx(() => [
                      (
                  [
                    _ctx.OrderStatus.CLIENT_REVIEW,
                    _ctx.OrderStatus.CLIENT_FEEDBACK,
                    _ctx.OrderStatus.DONE,
                    _ctx.OrderStatus.CANCELED,
                  ].includes(_ctx.request.order?.status?.key as OrderStatus)
                )
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(_ctx.dynamicPerformerTexts.description ||
                  _ctx.t("Let us know how the model creator did in this video.")), 1)
                          ], 64))
                        : (_openBlock(), _createBlock(_component_i18n_t, {
                            key: 1,
                            keypath: "Your video will ship out {deadlineText}.",
                            tag: "span"
                          }, {
                            deadlineText: _withCtx(() => [
                              _createVNode(_component_a_typography_text, { strong: "" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.videoDeadlineText), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_a_typography_title, {
                  level: 5,
                  class: "m-0"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Script / Instructions")), 1)
                  ]),
                  _: 1
                }),
                (_ctx.isReviewing)
                  ? (_openBlock(), _createBlock(_component_a_switch, {
                      key: 0,
                      checked: _ctx.scriptVisible,
                      "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.scriptVisible) = $event)),
                      "checked-children": _ctx.t('SHOW'),
                      "un-checked-children": _ctx.t('HIDE')
                    }, null, 8, ["checked", "checked-children", "un-checked-children"]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_InputTextArea, {
                    class: "request-card__script-textarea",
                    "default-value": 
              _ctx.request.script || _ctx.request.template?.script || _ctx.request.order?.notes
            ,
                    disabled: ""
                  }, null, 8, ["default-value"]), [
                    [_vShow, _ctx.isReviewing ? _ctx.scriptVisible : true]
                  ])
                ]),
                _: 1
              }),
              _renderSlot(_ctx.$slots, "extra"),
              (_ctx.showComments && _ctx.request.conversation?.messages?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.request.conversation.messages, (message) => {
                      return (_openBlock(), _createBlock(_component_a_comment, {
                        key: message?.id
                      }, {
                        avatar: _withCtx(() => [
                          _createVNode(_component_Avatar, {
                            theme: "white-blue",
                            class: "request-card__message-avatar"
                          })
                        ]),
                        author: _withCtx(() => [
                          _createVNode(_component_a_typography_title, {
                            level: 6,
                            class: "m-0 pointer-events-none"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.username === message?.author?.username
                    ? _ctx.t("You")
                    : message?.author?.nickname), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        content: _withCtx(() => [
                          _createElementVNode("div", _hoisted_10, _toDisplayString(message?.content), 1)
                        ]),
                        datetime: _withCtx(() => [
                          (message?.created)
                            ? (_openBlock(), _createBlock(_component_a_tooltip, {
                                key: 0,
                                title: _ctx.formatDateTime(message?.created)
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.formatDistanceToNow(new Date(message?.created), {
                    addSuffix: true,
                  })), 1)
                                ]),
                                _: 2
                              }, 1032, ["title"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 3
      }))
    : _createCommentVNode("", true)
}