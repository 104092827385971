import {
  Requests_requests_RequestsResults_results,
  Requests_requests_RequestsResults_results_outputVideos,
} from "@/api/order/__generated__/Requests";
import { useVimeoEmbed } from "@/shared/composables/useVimeoEmbed";
import sortBy from "lodash/sortBy";

const { getVimeoThumbnail } = useVimeoEmbed();

/** Returns sorted videos for Job with background sound prioritized */
export const getJobVideos = (
  request: Requests_requests_RequestsResults_results | null
): Requests_requests_RequestsResults_results_outputVideos[] => {
  if (!request?.outputVideos) {
    return [];
  }

  return [...request.outputVideos].sort(
    (a, b) =>
      Number(b?.hasBackgroundSound ?? 0) - Number(a?.hasBackgroundSound ?? 0)
  ) as Requests_requests_RequestsResults_results_outputVideos[];
};

export const getJobVideosEmbeds = (
  request: Requests_requests_RequestsResults_results | null
) => {
  if (!request?.outputVideos) {
    return [];
  }

  /** Get only those with embedUrl */
  const filteredJobVideos = getJobVideos(request).filter(
    (video) => !!video.embedUrl
  );

  /** Sort to make videos with background sound goes first
   * as per requirement to make them default */
  const sortedJobVideos = sortBy(
    filteredJobVideos,
    (video) => !video.hasBackgroundSound
  );

  /** Return only properties we need */
  return sortedJobVideos.map((video) => ({
    embedUrl: video.embedUrl,
    hasBackgroundSound: video.hasBackgroundSound,
  }));
};

/** Returns first available thumbnail URL */
export const getJobVideoThumbnail = (
  request: Requests_requests_RequestsResults_results | null
): string => {
  const embedUrl = getJobVideos(request).find(
    (video) => !!video.embedUrl
  )?.embedUrl;

  if (!embedUrl) {
    return "";
  }

  return getVimeoThumbnail(embedUrl);
};

/** Generate filename for file download based on provided data. */
export const generateJobDownloadFilename = (data: {
  orderId?: number | string | null;
  jobId?: number | string | null;
  /** Use to avoid overwrites to same file in ZIPs */
  index?: number | string | null;
  hasBackgroundSound?: boolean;
  fileExtension: string;
}) => {
  let filename = `kamilas4am-order-${data.orderId}`;

  if (data.jobId) {
    filename += `-job-${data.jobId}`;
  }
  if (data.hasBackgroundSound !== undefined) {
    filename += data.hasBackgroundSound
      ? "-with-bg-sound"
      : "-without-bg-sound";
  }
  if (data.index !== undefined && data.index !== null) {
    filename += `-${data.index}`;
  }

  if (data.fileExtension) {
    filename += `.${data.fileExtension}`;
  }

  return filename;
};
