import gql from "graphql-tag";

export const downloadRequestVideosGql = gql`
  query DownloadRequestVideos($input: DownloadRequestVideosInput!) {
    downloadRequestVideos(input: $input) {
      ... on DownloadVideosResults {
        results {
          __typename
          id
          downloadUrl
          embedUrl
          hasBackgroundSound
          job {
            id
            order {
              id
            }
          }
        }
      }

      ... on ResponseErrors {
        errors {
          __typename
          code
          displayMessage
          message
        }
      }
    }
  }
`;
