import { Requests_requests_RequestsResults_results_status } from "@/api/order/__generated__/Requests";
import { i18nTranslate } from "@/plugins/i18n";
import { JobStatus } from "@/shared/types/JobStatus";
import { compactObject } from "@/shared/utils/object";

/** See: #865c28z9e */
export const CUSTOM_JOB_STATUS_NAMES = {
  [JobStatus.OPEN]: i18nTranslate("Work In Progress"),
  [JobStatus.SUBMITTED_BY_PERFORMER]: i18nTranslate("Performer Submitted"),
  [JobStatus.CREATOR_VIDEO_CHECKED]: i18nTranslate("QA Review"),
  [JobStatus.CLIENT_APPROVED]: i18nTranslate("Selected"),
  [JobStatus.CLIENT_DENIED]: i18nTranslate("Not Selected"),
  [JobStatus.CLIENT_COMMENTED]: i18nTranslate("Requesting to Revise"),
  [JobStatus.CLIENT_COMMENT_ACCEPTED]: i18nTranslate("Revising"),
  [JobStatus.CLIENT_COMMENT_REJECTED]: i18nTranslate("Revision Rejected"),
};

export const getJobStatusName = <
  T extends Requests_requests_RequestsResults_results_status
>(
  status: T | null
) => {
  if (!status) return "";

  if (CUSTOM_JOB_STATUS_NAMES[status.key]) {
    return CUSTOM_JOB_STATUS_NAMES[status.key];
  }

  return status.name;
};

type CustomPerformerTexts = {
  headingTranslationString?: string;
  description?: string;
};
/**
 * Used on RequestCard (for Jobs list).
 * See: #865d32q19
 */
export const PERFORMER_JOB_STATUS_TEXTS: {
  [k: string]: CustomPerformerTexts;
} = {
  [JobStatus.OPEN]: {
    headingTranslationString: "{name} is working on your video.",
  },
  [JobStatus.SUBMITTED_BY_PERFORMER]: {
    headingTranslationString: "{name} uploaded the video for our checking.",
  },
  [JobStatus.CREATOR_VIDEO_CHECKED]: {
    headingTranslationString: "{name} uploaded the video for our checking.",
  },
  [JobStatus.CLIENT_APPROVED]: {
    description: "Let us know how the model creator did in this video.",
  },
  [JobStatus.CLIENT_DENIED]: {
    description: "Let us know how the model creator did in this video.",
  },
  [JobStatus.CLIENT_COMMENTED]: {
    description: "We'll check on your comments for our approval.",
  },
  [JobStatus.CLIENT_COMMENT_ACCEPTED]: {
    description: "is applying the revisions.",
  },
  [JobStatus.CLIENT_COMMENT_REJECTED]: {
    description: "Let us know how the model creator did in this video.",
  },
};

export const getCustomPerformerTexts = <
  T extends Requests_requests_RequestsResults_results_status
>(
  status: T | null | undefined
): CustomPerformerTexts => {
  if (!status) return {};

  const data = PERFORMER_JOB_STATUS_TEXTS[status.key];
  if (data) {
    return compactObject({
      headingTranslationString: data.headingTranslationString,
      description: data.description,
    });
  }

  return {};
};
