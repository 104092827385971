import {
  DownloadRequestVideos,
  DownloadRequestVideosVariables,
  DownloadRequestVideos_downloadRequestVideos_DownloadVideosResults,
} from "@/api/jobs/__generated__/DownloadRequestVideos";
import { downloadRequestVideosGql } from "@/api/jobs/downloadRequestVideos";
import { i18nTranslate } from "@/plugins/i18n";
import { apiErrorCodes } from "@/shared/utils/constants";
import { downloadFilesToZip } from "@/shared/utils/downloadUtils";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { generateJobDownloadFilename } from "@/shared/utils/jobVideoUtils";
import message from "@/shared/utils/message";
import { useQuery } from "@vue/apollo-composable";
import { Ref, computed, nextTick, ref } from "vue";

export const useDownloadRequestVideos = (jobId: Ref<string | null>) => {
  /** Set to true once `downloadVideos` method is called, to avoid automatically fetching query. */
  const queryEnabled = ref(false);

  const { loading: downloadRequestVideosLoading, refetch } = useQuery<
    DownloadRequestVideos,
    DownloadRequestVideosVariables
  >(
    downloadRequestVideosGql,
    () => ({ input: { jobId: jobId.value ?? "" } }),
    () => ({ enabled: queryEnabled.value })
  );

  const downloadLoading = ref(false);

  const downloadVideos = async () => {
    try {
      downloadLoading.value = true;
      queryEnabled.value = true;

      await nextTick(async () => {
        const response = await refetch({ input: { jobId: jobId.value ?? "" } });
        const parsedResponse =
          parseGqlResponse<DownloadRequestVideos_downloadRequestVideos_DownloadVideosResults>(
            "DownloadVideosResults",
            response,
            // Hide error if not allowed to download videos. Just return empty array.
            apiErrorCodes.DOWNLOAD_VIDEOS_NOT_ALLOWED_ERROR
          );

        if (parsedResponse.error) {
          // Error handled by parseGqlResponse
          return;
        }

        // Only download videos also with embed URLs. Other video objects without embed URLs are invalid.
        const outputVideosWithLinks = parsedResponse.data?.results?.filter(
          (video) => !!video?.embedUrl
        );
        const links = outputVideosWithLinks!.map((item, index) => ({
          link: item?.downloadUrl ?? "",
          filename: generateJobDownloadFilename({
            orderId: item?.job?.order?.id,
            jobId: item?.job?.id,
            hasBackgroundSound: item?.hasBackgroundSound,
            index: index,
            fileExtension: "mp4",
          }),
        }));

        const hasError = await downloadFilesToZip(
          links,
          generateJobDownloadFilename({
            orderId: jobId.value,
            fileExtension: "zip",
          })
        );

        if (hasError) {
          message.warning(
            i18nTranslate(
              "Failed to download some of the videos. Please check your network connection and retry. If issue persists, please contact us so we can assist you."
            )
          );
        } else {
          message["success"](
            i18nTranslate(
              "Your videos has started to download! Please check your Downloads folder."
            )
          );
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      downloadLoading.value = false;
    }
  };

  return {
    downloadVideos,
    downloadLoading: computed(
      () => downloadRequestVideosLoading.value || downloadLoading.value
    ),
  };
};
