import gql from "graphql-tag";

export const currentSubscriptionPlanGql = gql`
  query CurrentSubscriptionPlan {
    profile {
      ... on User {
        subscriptionPlan {
          id
          created
          modified
          name
          price
          maxVideoCount
          maxPerformerCount
          description
          isActive
          sortWeight
        }
      }

      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
