import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, Suspense as _Suspense } from "vue"

const _hoisted_1 = {
  class: "embed-tabs",
  ref: "embedCarouselRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_VimeoEmbedded = _resolveComponent("VimeoEmbedded")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_a_typography_title, {
          key: 0,
          level: 5,
          class: "m-0 mb-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Suspense, { onResolve: _ctx.handleInjectEmbedScripts }, {
      fallback: _withCtx(() => [
        _createVNode(_component_a_skeleton, {
          active: "",
          class: "embed-tabs__loading",
          title: { width: '100%' },
          paragraph: { rows: 2 }
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_tabs, {
          activeKey: _ctx.models.activeKey,
          "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.models.activeKey) = $event)),
          "tab-position": "bottom",
          class: "embed-tabs__tabs",
          type: "card",
          onChange: _ctx.handleAfterChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoEmbeds, (video, index) => {
              return (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: index,
                tab: _ctx.tabLabel(video.hasBackgroundSound)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_VimeoEmbedded, {
                    "embed-src": video.embedUrl
                  }, null, 8, ["embed-src"])
                ]),
                _: 2
              }, 1032, ["tab"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["activeKey", "onChange"])
      ]),
      _: 1
    }, 8, ["onResolve"]))
  ], 512))
}